import { ObjectType } from "../masterView/common";

export const messages: ObjectType = {
  selectAll: "Select All",
  reasonForEditing: "Reason For Editing",
  enterDecimalValue: "Please enter value in decimal",
  properCurrencyFormat: "Please enter value in proper currency format",
  unidentifiedUser: "Unidentified User",
  fieldRequired: "This field is required",

  // Audit Paths
  sellerPartyIdAuditPath: "data.loan.loanConfig.loanDetailId.sellerPartyId",
  toorakProductAuditPath: "data.loan.loanInfo.toorakProduct",
  cutOffDateAuditPath: "data.loan.loanInfo.cutOffDate",
  originationDateAuditPath: "data.loan.loanInfo.originationDate",
  firstPaymentDateAuditPath: "data.loan.loanInfo.firstPaymentDateOfLoan",
  maturityDateAuditPath: "data.loan.loanInfo.originalMaturityDate",
  originalCreditReportDateAuditPath:
    "data.loan.loanInfo.originalCreditScoreReportDate",
  selectedRecourseAuditPath: "data.loan.loanInfo.recourseString",
  recourseAuditPath: "data.loan.loanInfo.recourseString",
  loanPurposeAuditPath: "data.loan.loanInfo.loanPurpose",
  creditEventAuditPath: "data.loan.loanInfo.creditEvent",
  selectedCrossAuditPath: "data.loan.loanInfo.crossCollaterlization",
  crossCollaterlizationAuditPath: "data.loan.loanInfo.crossCollaterlization",
  condoEligibilityAuditPath: "data.loan.loanInfo.condoEligibility",
  financialInterestReserveAuditPath:
    "data.loan.loanEconomics.financedInterestReserve",
  loanAmountAuditPath: "data.loan.loanEconomics.originalLoanAmount",
  maxLoanAmountAuditPath: "data.loan.loanEconomics.originalMaximumLoanAmount",
  cutOffLoanAmountAuditPath: "data.loan.loanEconomics.cutOffDateLoanAmount",
  cutOffMaxLoanAmountAuditPath:
    "data.loan.loanEconomics.cutOffDateMaximumLoanAmount",
  budgetAmountAuditPath: "data.loan.loanEconomics.totalBudgetAmount",
  cashOutAmountAuditPath: "data.loan.loanEconomics.cashOutAmount",
  financedBudgetAmountAuditPath: "data.loan.loanEconomics.financedBudgetAmount",
  rateTypeAuditPath: "data.loan.loanEconomics.rateType",
  armIndexAuditPath: "data.loan.loanEconomics.armIndex",
  grossArmMarginAuditPath: "data.loan.loanEconomics.grossArmMargin",
  includeOutOfPocketBudgetInARLTVAuditPath:
    "data.loan.loanEconomics.includeOutOfPocketBudgetInARLTV",
  initialRateAdjustmentPeriodAuditPath:
    "data.loan.loanEconomics.initialRateAdjustmentPeriod",
  initialPayAdjustmentPeriodAuditPath:
    "data.loan.loanEconomics.initialPayAdjustmentPeriod",
  initialInterestRateDownAuditPath:
    "data.loan.loanEconomics.initialInterestRateDown",
  initialInterestRateUpAuditPath:
    "data.loan.loanEconomics.initialInterestRateUp",
  lifetimeMaxRateAuditPath: "data.loan.loanEconomics.lifetimeMaxRate",
  lifetimeMinRateAuditPath: "data.loan.loanEconomics.lifetimeMinRate",
  subsequentInterestRateDownAuditPath:
    "data.loan.loanEconomics.subsequentInterestRateDown",
  subsequentInterestRateUpAuditPath:
    "data.loan.loanEconomics.subsequentInterestRateUp",
  paymentAdjustmentFrequencyAuditPath:
    "data.loan.loanEconomics.paymentAdjustmentFrequency",
  rateAdjustmentFrequencyAuditPath:
    "data.loan.loanEconomics.rateAdjustmentFrequency",
  initialPeriodicCapAuditPath: "data.loan.loanEconomics.initialPeriodicCap",
  lifeRateCapAuditPath: "data.loan.loanEconomics.lifeRateCap",
  subsequentPeriodicCapAuditPath: "data.loan.loanEconomics.subsequentPeriodicCap",
  selectedFundingChannelAuditPath: "data.loan.loanDetailId.fundingType",
  fundingTypeAuditAuditPath: "data.loan.loanDetailId.fundingType",
  armLookBackDaysAuditPath: "data.loan.loanEconomics.armLookBackDays",
  armRoundFlagAuditPath: "data.loan.loanEconomics.armRoundFlag",
  armRoundingFactorAuditPath: "data.loan.loanEconomics.armRoundingFactor",
  currentLoanBalanceAuditPath: "data.loan.loanEconomics.currentLoanBalance",
  fundingTypeAuditPath: "data.loan.loanDetailId.fundingType",
  numberOfPropertiesAuditPath: "data.loan.loanInfo.numberOfProperties",
  primaryLoanIDAuditPath: "data.loan.loanInfo.primaryLoanId",
  interestRateAuditPath: "data.loan.loanEconomics.interestRateAsOfCutOffDate",
  accrualTypeAuditPath: "data.loan.loanEconomics.accrualType",
  discountPointsAuditPath:
    "data.loan.loanEconomics.totalOriginationAndDiscountPoints",
  totalOriginationAndDiscountPointsAuditPath:
    "data.loan.loanEconomics.totalOriginationAndDiscountPoints",
  interestOnlyPeriodAuditPath: "data.loan.loanEconomics.interestOnlyPeriod",
  prepayPenaltyTypeAuditPath: "data.loan.loanEconomics.prepayPenaltyType",
  prepaymentPenaltyAuditPath: "data.loan.loanEconomics.prepaymentPenalty",
  subordinateFinancingAuditPath: "data.loan.loanEconomics.subordinateFinancing",
  businessPurposeOccupancyAuditPath:
    "data.loan.loanEconomics.businessPurposeOccupancy",
  costToLoanRatioAuditPath: "data.loan.loanEconomics.costToLoanRatio",
  borrowerLiquidityAuditPath: "data.loan.loanEconomics.borrowerLiquidity",
  extensionOptionAuditPath: "data.loan.loanInfo.extensionOptions",
  selectedLoanStructureAuditPath: "data.loan.loanInfo.loanStructure",
  selectedToorakProductAuditPath: "data.loan.loanInfo.toorakProduct",
  noOfPropertiesAuditPath: "data.loan.loanInfo.noOfProperties",
  primaryLoanIdAuditPath: "data.loan.loanInfo.primaryLoanId",
  extensionOptionsAuditPath: "data.loan.loanInfo.extensionOptions",
  hardCostAuditPath: "data.loan.loanEconomics.hardCost",
  existDebtAuditPath: "data.loan.loanEconomics.existDebt",
  softCostAuditPath: "data.loan.loanEconomics.softCost",
  originalLoanAmountAuditPath: "data.loan.loanEconomics.originalLoanAmount",
  interestRateAsOfCutOffDateAuditPath:
    "data.loan.loanEconomics.interestRateAsOfCutOffDate",
  rehabBudgetAuditPath: "data.loan.loanEconomics.totalBudgetAmount",
  interestReserveAuditPath: "data.loan.loanEconomics.interestReserve",
  pledgeOfEquityAuditPath: "data.loan.loanInfo.pledgeOfEquity",
  exitStrategyAuditPath: "data.loan.loanInfo.exitStrategy",
  cashOutFlagAuditPath: "data.loan.loanInfo.cashoutFlag",
  cashoutFlagAuditPath: "data.loan.loanInfo.cashoutFlag",
  loanStructureAuditPath: "data.loan.loanInfo.loanStructure",
  borrowerProceedsAuditPath: "data.loan.loanInfo.borrowerProceeds",
  loanTermAuditPath: "data.loan.loanInfo.loanTerm",
  expectedClosingDateAuditPath: "data.loan.loanInfo.expectedClosingDate",
  irFundingSourceAuditPath: "data.loan.loanInfo.irFundingSource",
  isPermitApprovalsAndFullPlansInPlaceAuditPath:
    "data.loan.loanInfo.isPermitApprovalsAndFullPlansInPlace",
  prepaymentPenaltyMonthsAuditPath:
    "data.loan.loanEconomics.prepaymentPenaltyMonths",
  lockStatusAuditPath: "data.loan.investorDetails.lockStatus",
  loanNumberAuditPath: "data.loan.investorDetails.loanNumber",
  nameAuditPath: "data.loan.investorDetails.name",
  lockDateAuditPath: "data.loan.investorDetails.lockDate",
  expirationDateAuditPath: "data.loan.investorDetails.expirationDate",
  investorInterestRateAuditPath: "data.loan.investorDetails.interestRate",
  priceAuditPath: "data.loan.investorDetails.price",
  discountPointPercentageAuditPath: "data.loan.loanDetailId.discountPointPercentage",
  discountPointAmountAuditPath: "data.loan.loanDetailId.discountPointAmount",
};